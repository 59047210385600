import { defineStore } from 'pinia';

type Org = {
  id: string,
  name: string,
};

type Acc = {
  loaded: boolean,
  userId: string,
  email: string,
  isAdmin: boolean,
  org: Org,
  orgs: Org[],
  teams: {
    [id: string]: {
      id: string,
      name: string,
      role: string,
    }
  }
}

export const useAccStore = defineStore('acc', {
  // @ts-expect-error something something pinia
  id: 'acc',
  state: (): Acc => ({
    loaded: false,
    userId: null,
    email: null,
    isAdmin: null, // is emprove staff
    org: null, // {id, name} - current active ORG
    orgs: null, // [{id, name}] - all orgs of this user
    // team: null, // {id, name} - current active team
    teams: null, // {id: {name, role}} - list of teams in this ORG
  }),

  getters: {
    defaultTeam: (state) => {
      // default team - only for admin
      if (state.teams === null) {
        return;
      }
      for (const team of Object.values(state.teams)) {
        if (team.role === "admin") {
          return team.id;
        }
      }
    },
  },

  actions: {
    init(payload) {
      this.email = payload.email;
      this.userId = payload.userId;
      this.isAdmin = payload.admin;
      this.org = payload.org;
      this.orgs = payload.orgs;
      this.teams = payload.teams;
      this.loaded = true;
    },
    setUnauth() {
      this.loaded = true;
    },
    reset() {
      this.email = null;
      this.userId = null;
      this.isAdmin = null;
      this.org = null;
      this.orgs = null;
      this.teams = null;
    },
  },
})
